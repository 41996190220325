import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { authConfig } from '../model/auth.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * emits when a new token is received
   */
  onTokenReceived: EventEmitter<void> = new EventEmitter<void>();
  onError: EventEmitter<void> = new EventEmitter<void>();

  constructor(public oAuthService: OAuthService, private router: Router) {}

  /**
   * configures OAuthService and triggers authentication process
   */
  onInit(): void {
    // COLTON: you had subscribeToEvents in the constructor but I believe it belongs here as part of the init
    this.subscribeToEvents();
    this.oAuthService.configure(authConfig);
    this.oAuthService.setStorage(localStorage); // this is deprecated but the documented method was not working
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.hasValidAccessToken()) {
        this.oAuthService.initCodeFlow(this.router.url);
      }
    });
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  /**
   * subscribe to OAuthService events and then translate and emit them from the Auth Service
   */
  private subscribeToEvents() {
    this.oAuthService.events.subscribe((event) => {
      if (event.type === 'token_received') {
        // Load user profile
        this.oAuthService.loadUserProfile().then((userProfile) => {
          // Set role based on the user job title
          this.setUserRole(userProfile);
        });
        this.onTokenReceived.emit();
      }
      if (event.type.includes('_error')) {
        console.log(event);
        this.onError.emit();
      }
    });
  }

  private setUserRole(userProfile: UserInfo) {
    let userRole = "unknown";
    let userAirlines = [];
    
    if (userProfile.groups) {
      let socAdGroups = ["AS System Operations Div","QXOPS_SOC", "QXFLTOPS_SOC_CLP"];
      let guestOpsAdGroups = ["AS Customer Care Dept"];
      if(userProfile.groups.filter(value => socAdGroups.includes(value.trim())).length > 0) {
        userRole = "sectorManager";
        if (userProfile.company == 'AS') {
          userAirlines = ['AS','OO'];
        }
        if (userProfile.company == 'QX') {
          userAirlines = ['QX'];
        }
      }
      else if (userProfile.groups.filter(value => guestOpsAdGroups.includes(value.trim())).length > 0){
        userRole = 'guestOps';
        userAirlines = ['AS','QX','OO'];
      }
      else if (!environment.production) {
        userRole = "sectorManager";
        userAirlines = ['AS','QX','OO'];
      }
    }

    localStorage.setItem('user_role', userRole);
    localStorage.setItem('user_airlines', JSON.stringify(userAirlines));
  }

  /**
   * returns the current access_token
   */
  public getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  /**
   * checks if the current access_token is valid
   */
  public hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }
}
