import {Component, OnInit,  OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[];
  authenticating = true;
  constructor(private authService: AuthService, private router: Router) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.onTokenReceived.subscribe(() => {
        
        //Read the URL from the state. State URL is set when calling initCodeFlow.
        let stateUrl = this.authService.oAuthService.state;
        if (stateUrl) {
          //Check to see if the URL is encoded. Encoded URL will not have /
          if (stateUrl.startsWith('/') === false) {
            //Decode the URL if it is encoded. 
            stateUrl = decodeURIComponent(stateUrl);
          }
          //To load the default page when no page specified.
          if (stateUrl === '/') {
            stateUrl = 'home';
          }
        }
        else {
          stateUrl = 'home';
        }

        this.router.navigateByUrl(stateUrl);
      })
    );

    this.subscriptions.push(
      this.authService.onError.subscribe(() => {
        this.authenticating = false;
      })
    );

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
