import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AirtalkService {

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Ocp-Apim-Subscription-Key': environment.airtalkSubscriptionKey,
      'as-frameworkversion': '1.0',
      'as-callername': 'TMWEB',
      'as-userid': 'AirTalk'
    })
  };

  constructor(private oauthService: OAuthService, private http: HttpClient) { }

  getTwilioToken(): Observable<AirtalkTokenResponse> {

    var body = { 'oauthToken': this.oauthService.getAccessToken() };
    var airtalkTokenCreateUrl = environment.airtalkUrl + '/token/create';
    console.log(airtalkTokenCreateUrl);
    
    return this.http.post<AirtalkTokenResponse>(airtalkTokenCreateUrl, body, this.httpOptions).pipe(
      catchError(this.handleError<AirtalkTokenResponse>('getTwilioToken', null))
    );
  }

  getConfiguration(): Observable<AirTalkConfiguration> {
    console.log('airtalkService.getConfiguration');

    console.log(this.httpOptions);
    
    var airtalkConfigurationUrl = environment.airtalkUrl + '/configuration';
    console.log(airtalkConfigurationUrl);
    
    return this.http.get<AirTalkConfiguration>(airtalkConfigurationUrl, this.httpOptions).pipe(
      catchError(this.handleError<AirTalkConfiguration>('getConfiguration', {'roles': null }))
    );
  }

    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); 

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
export interface AirtalkTokenResponse {
  twilioToken: string;
  firstName: String;
  lastName: String;

}

export interface Theme {
  textColor: string;
  backgroundColor: string;
}

export interface Themes {
  default: Theme;
}

export interface Position {
  position: string;
  displayName: string;
}

export interface Role {
  role: string;
  displayName: string;
  abbreviation: string;
  themes: Themes;
  positions: Position[];
}

export interface AirTalkConfiguration {
  roles: Role[];
}
