import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.provider,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth/',
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.clientId,
  // The SPA's secret for openid connect - not needed, poses a security risk if included here
  dummyClientSecret: '',
  // set the scope for the permissions the client should request
  scope: 'openid profile email',
  // set response type, use code for Code Flow + PKCE
  responseType: 'code',
  // request an id_token, must be set to false
  oidc: false,
  // For localhost purposes only
  requireHttps: false,
  // percent (95%) of token lifetime before token is refreshed
  timeoutFactor: 0.95
};